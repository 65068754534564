/**
 * project: Pimcore -
 * Created by EBiermann on 15.01.2020.
 */

const Config = {
	components: [
		//loaded by preloadController
		//{ classname: 'Preloader', selector: '.preloader', loadInPimcoreEditmode: false }

		{ classname: 'Header', selector: '.header', loadInPimcoreEditmode: true },
		{ classname: 'Hamburger', selector: '.hamburger', loadInPimcoreEditmode: true},
		{ classname: 'Breadcrumb', selector: '.section--breadcrumb', loadInPimcoreEditmode: true },
		{ classname: 'Intro', selector: '.intro', loadInPimcoreEditmode: false },
		{ classname: 'Map', selector: '.map', loadInPimcoreEditmode: true},
		{ classname: 'Gallery', selector: '.gallery', loadInPimcoreEditmode: true},
		{ classname: 'Timeline', selector: '.timeline', loadInPimcoreEditmode: false},
		{ classname: 'Form', selector: '.form--std', loadInPimcoreEditmode: true },

		//
		//{ classname: 'Section', selector: '.section', loadInPimcoreEditmode: true },
		//{ classname: 'Gallery', selector: '.gallery', loadInPimcoreEditmode: true },
		//{ classname: 'Form', selector: '.form', loadInPimcoreEditmode: false },
		//{ classname: 'Slideshow', selector: '.slideshow', loadInPimcoreEditmode: false },
		//{ classname: 'Jumplinks', selector: '.jumplinks', loadInPimcoreEditmode: false },
		//{ classname: 'Video', selector: '.video', loadInPimcoreEditmode: false},
		//{ classname: 'Totop', selector: '.to-top', loadInPimcoreEditmode: false },
		//{ classname: 'Product', selector: '.product', loadInPimcoreEditmode: true },
		//{ classname: 'Zoomimage', selector: '.zoom-image', loadInPimcoreEditmode: true },
		//{ classname: 'Lightbox', selector: '.lightbox', loadInPimcoreEditmode: false }
	]
}

export default Config;