/**
 * project: Pimcore -
 * Created by EBiermann on 01.10.2019.
 */
"use strict";

class Throttle {

	static addEvent(eventType, customName, target) {
		target = target || window;
		let running = false;
		const eventFunction = originEvent => {
			if (running) return;
			running = true;

			requestAnimationFrame(() => {
				target.dispatchEvent(new CustomEvent(customName, {
					detail: {
						originEvent: originEvent
					}
				}));
				running = false;
			});
		}
		target.addEventListener(eventType, eventFunction);
	}
}

export default Throttle