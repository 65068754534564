/**
 * project: Pimcore
 * Created by EBiermann on 01.10.2019.
 */
import Events from "../../site.events";
import * as Utils from "../../utilities/Utilities";
import ComponentFactory from '../../factories/ComponentFactory';

class PreloadController {
	constructor(app) {
		this._app = app;
		this._register = [];
		this._allPreloaded = false;
		this._checkIntervall = null;
		this._preloaderScreen = null;
	}

	//public;

	async start() {
		return await this._start();
	}

	//private

	async _start() {
		return await this._init();
	}

	async _init() {
		if (this._app.lifecycle.elements.preloader) {
			this._preloaderScreen = await this._initPreloaderScreen();
			this._attachEventsAndListeners();
		}
		else {
			this._attachEventsAndListeners();
		}

		return Promise.resolve();
	}

	async _initPreloaderScreen() {
		let preloaderClass = await ComponentFactory('Preloader');
		this._preloaderScreen = new preloaderClass(this._app.lifecycle.elements.preloader, this._app.lifecycle.elements.wrapper, this._app);
		return await  this._preloaderScreen;
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.preload.register, this._onPreloadRegister.bind(this));
		window.addEventListener(Events.preload.unregister, this._onPreloadUnregister.bind(this));
		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		window.addEventListener(Events.preload.xhr, this._onPreloadXhr.bind(this));
	}

	_onPreloadRegister(evt) {
		this._register.push(evt.detail.instance);
	}

	_onPreloadUnregister(evt) {
		const index = this._register.indexOf(evt.detail.instance);
		if (index !== -1) {
			this._register.splice(index, 1);
		}
	}

	_onPreloadXhr() {
		this._onPreloadBegin();
	}

	_onPreloadBegin() {
		//this._check
		console.log("preloadController begin preload check", this._register);
		this._checkIntervall = setInterval(() => {
			this._checkPreloadState();
			if (this._allPreloaded) {
				clearInterval(this._checkIntervall);
				console.log("all elements preloaded dispatch finish event", this._register);
				Utils.DispatchEvent.dispatch(Events.preload.finished, {

				});
			}
		}, 100)

	}

	_checkPreloadState() {
		if (this._register.length > 0) {
			this._allPreloaded = true;
			this._register.forEach((instance, idx) => {
				if (!instance.isPreloaded()) {
					console.log("instance of ", instance, "is not preloaded");
					instance.preload();
					this._allPreloaded = false;
				}
			});
		}
		else {
			this._allPreloaded = true;
		}
	}
}

export default PreloadController;