/**
 * project: General
 * Created by EBiermann on 01.10.2019.
 */


"use strict";

import Events from "../site.events";
import Config from "../site.config";
import ComponentFactory from "../factories/ComponentFactory";

class ComponentHandler {
	constructor(watchElement, app) {
		/*
		this.components = [
				{classname: 'Gallery', selector: '.area-gallery'},
				{classname: 'Slideshow', selector: '.area-slider'}
			]

			this.instances = {
			}

			this._init();
		 */

		this._app = app;
		this._watchElement = watchElement;
		this._components = {};
		this._instances = {}
		this._afterInitCallback = null;

		this._init();
	}

	//public

	doAfterInitComponents(doThis) {
		this._afterInitCallback = doThis;
	}

	async initComponents(referenceElement) {
		return this._initComponents(referenceElement);
	}

	getInstance() {
		return this;
	}

	/**
	 *
	 * @param element
	 * @param type
	 */
	getComponentInstanceByElement(element, type) {
		return this._getComponentInstanceByElement(element, type);
	}

	register() {
		//do later
	}

	//private

	_init() {
		this._components = Config.components;
		this._components.forEach((component, idx) => {
			this._instances[component.className] = [];
		})

		this._attachEventsAndListeners();
	}

	_attachEventsAndListeners() {
		window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));
	}

	_onLayoutMutationOberserved(evt) {
		this._initComponents();
	}

	async _initComponentElements(componentElements, component, referenceElement) {
		let componentClass, createdInstances = {};
		const componentElementPromises = Array.from(componentElements).map( async element => {
			if (!element.dataset.init) {
				element.dataset.init = 'loading';
				componentClass = await ComponentFactory(component.classname);
				console.log("create new ",component.classname, this._app, componentClass);
				let io = {
					element: element,
					referenceElement: referenceElement,
					object: new componentClass(element, referenceElement, this._app)
					//object: new window[component.classname](element, referenceElement
				}


				if (!createdInstances[component.classname]) {
					createdInstances[component.classname] = [];
					this._instances[component.classname] = [];
				}

				createdInstances[component.classname].push(io);
				this._instances[component.classname].push(io);

				return componentClass;
			}
		})

		const elements = await Promise.all(componentElementPromises);
		return elements;
	}

	async _initComponents(referenceElement) {
		let componentElements;
		referenceElement = referenceElement ? referenceElement : this._watchElement;

		const componentPromises = this._components.map(async component => {
			if (component.loadInPimcoreEditmode === false && window.editmode) return; //skip component
			if (component.selector) {
				componentElements = referenceElement.querySelectorAll(component.selector);
				const componentElementPromises = await this._initComponentElements(componentElements, component, referenceElement);
				const elements = await Promise.all(componentElementPromises)
				return elements;
			}
		})

		const components = await Promise.all(componentPromises);

		if (typeof this._afterInitCallback === 'function') {
			this._afterInitCallback();
		}
		return components;
	}
	_getComponentInstanceByElement(element, type) {
		let foundInstance = false;
		if (this._instances[type]) {
			this._instances[type].forEach((instance, idx) => {
				if (instance.element == element) {
					try {
						foundInstance = instance.object.getInstance();
					}
					catch (e) {
						console.error(e);
					}
				}
			})
		}
		return foundInstance;
	}
}

export default ComponentHandler;