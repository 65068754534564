/**
 * project: Pimcore
 * Created by EBiermann on 15.01.2020.
 */


const DEBUG = window.devmode ?? true;

if(!DEBUG){
	if(!window.console) window.console = {};
	const methods = ["log", "debug", "warn", "info", "error"];
	for(let i=0;i<methods.length;i++){
		console[methods[i]] = function(){};
	}
}
let app = app || {};

import {gsap} from "gsap/all";
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollToPlugin,ScrollTrigger);

import scrollLock from 'scroll-lock/dist/scroll-lock';
import LazyLoad from "vanilla-lazyload";

import Events from './site.events';
import Config from './site.config';
import * as Utils from './utilities/Utilities';
import * as Handlers from './handlers/Handlers';

import PreloadController from "./classes/preloader/PreloadController";

class Main {
	constructor() {
		this.started = false;
		this.lifecycle = {
			elements: {
				body: document.body,
				wrapper: document.body,
				header: document.querySelector('.header')
			},
			has: {
			},
			animating: {
			},
			visible: {
				header: false
			}
		};
		this.handlers = {};
		this.components = {};
		
		this.keys = {
			enter: 13,
			space: 32,
			tab: 9,
			esc: 27,
			left: 37,
			right: 39,
			up: 38,
			down: 50
		};

		this.Events = Events;
		this.Config = Config;
		this.Utils = Utils;
		this.Handlers = Handlers;

		this._resizeFinishedTimeout = null;

		this._init();

	}

	start() {
		if (this.started) return;
		this.started = true;

		//Utils.DispatchEvent.dispatch(Events.preload.begin, {}, window, true, false);
		//Utils.DispatchEvent.dispatch(Events.preload.finished, {}, window, true, false);
	}

	async _init() {
		this._attachEventsAndListeners();

		this.handlers.preloadController = new PreloadController(this);
		this.handlers.mutation = new Handlers.MutationHandler(this.lifecycle.elements.wrapper);
		this.handlers.intersection = new Handlers.IntersectionHandler();
		this.handlers.resize = new Handlers.ResizeHandler();
		this.handlers.component = new Handlers.ComponentHandler(this.lifecycle.elements.wrapper, this);

		scrollLock.addFillGapTarget(this.lifecycle.elements.header);

		await this._initComponents();
		await this.handlers.preloadController.start();

		Utils.DispatchEvent.dispatch(Events.preload.begin, {}, window, true, false, true);
	}

	async _initComponents() {
		await this.handlers.component.initComponents();

		this.components.lazyload = new LazyLoad({
			threshold: 100,
			//load_delay: 2000,
			elements_selector: '.lazy',
			class_loading: 'lazy--loading',
			class_loaded: 'lazy--loaded',
			class_error: 'lazy--error',
			callback_enter: (e) => {
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICTURE') { parent = parent.parentNode }
					if (!Utils.Helper.isFunction(parent.classList)) return;
					parent.classList.add('lazy');
				}
			},

			callback_loading: (e) => {
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICTURE') { parent = parent.parentNode }
					parent.classList.add('lazy--loading');
				}

				//picturefill({ reevaluate: true });
			},
			callback_loaded: (e) => {
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICTURE') { parent = parent.parentNode }
					parent.classList.add('lazy--loaded');
					parent.classList.remove('lazy--loading');

				}
				//picturefill({ reevaluate: true });
			},
			callback_error: (e) => {
				console.error("lazyload image error:",e.parentNode, e);
				if(e.parentNode) {
					let parent = e.parentNode;
					if (parent.tagName == 'PICUTRE') { parent = parent.parentNode }
					parent.classList.add('lazy--error');
				}
			}
		});
	}

	_attachEventsAndListeners() {
		Utils.Throttle.addEvent('resize', 'throttledResize');
		Utils.Throttle.addEvent('scroll', 'throttledScroll');
		
		window.addEventListener('orientationchange', this._onOrientationChange.bind(this));
		window.addEventListener('throttledResize', this._onOptimiziedResize.bind(this));
		window.addEventListener('throttledScroll', this._onOptimiziedScroll.bind(this));

		window.addEventListener(Events.preload.begin, this._onPreloadBegin.bind(this));
		window.addEventListener(Events.preload.finished, this._onPreloadFinished.bind(this));
		window.addEventListener(Events.layout.mutation.oberserved, this._onLayoutMutationOberserved.bind(this));


		window.addEventListener(Events.navigation.do.show, this._disableScroll.bind(this));
		window.addEventListener(Events.navigation.do.hide, this._enableScroll.bind(this));
		window.addEventListener(Events.navigation.clickable.show, this._disableScroll.bind(this));
		window.addEventListener(Events.navigation.clickable.hide, this._enableScroll.bind(this));

	}

	_disableScroll(evt) {
		scrollLock.disablePageScroll(evt.detail.element);
	}

	_enableScroll(evt) {
		scrollLock.enablePageScroll(evt.detail.element);
	}

	_onPreloadBegin(evt) {
		console.log("main: onPreloadBegin", window.location.hash);

		window.scrollTo(0, 0);
		if (window.location.hash) {
			//const anchorElement = document.querySelector(window.location.hash);
			//if (anchorElement) {
			//	//anchorElement.scrollIntoView();
			//
			//	gsap.to(window, {
			//		scrollTo:
			//	});
			//}
		}
		else {
			window.scrollTo(0, 1);
		}

	}

	_onPreloadFinished(evt) {
		console.log("main: onPreloadFinished", window.location.hash);
		this.lifecycle.elements.body.classList.add('layout--preloading-done');
		//setTimeout(() => window.scrollTo(0, 1), 2000);

		if (!window.editmode) {
			//gsap.to(this.lifecycle.elements.body, {
			//	opacity: 1,
			//	duration: 0.3,
			//	ease: 'power2.in'
			//})


			if (window.location.hash) {

				Utils.DispatchEvent.dispatch(Events.page.load.hash, {
					hash: window.location.hash.replace('#', '')
				});

				gsap.to(window, {
					delay: 0.5,
					duration: 1,
					scrollTo: {
						y: window.location.hash,
						offsetY: 200,
						//offsetY: Utils.Helper.getHeight(this.lifecycle.header, true)
					}
				});

			}

		}
	}

	_onLayoutMutationOberserved(evt) {

	}

	_onOrientationChange(evt) {
		this._calculateDimensions(evt, false);
	}

	_onOptimiziedResize(evt) {
		this._calculateDimensions();
		clearTimeout(this._resizeFinishedTimeout);
		this._resizeFinishedTimeout = setTimeout(() => {
			Utils.DispatchEvent.dispatch(Events.layout.resize.did);
		}, 250);
	}

	_onOptimiziedScroll(evt) {
		let oe = evt.detail.originEvent, st = Utils.Helper.getScrollPosition('y');

		if (st > this.lifecycle.lastScrollTop) {
			this.lifecycle.scrollDirection = 'down';
		}
		else {
			this.lifecycle.scrollDirection = 'up';
		}

		Utils.DispatchEvent.dispatch(Events.layout.scroll.did, {
			scrollEvent: evt.detail.originEvent,
			scrollTop: st,
			scrollDirection: this.lifecycle.scrollDirection
		});

		this.lifecycle.lastScrollTop = st;

	}

	_calculateDimensions(evt, initialCall) {
		this.lifecycle.viewportHeight = Utils.Helper.getViewportHeight();
		this.lifecycle.viewportWidth = Utils.Helper.getViewportWidth();
		this.lifecycle.viewportRatio = app.lifecycle.viewportWidth / app.lifecycle.viewportHeight;

		Utils.DispatchEvent.dispatch(Events.layout.dimension.check, {
			initial: initialCall ? true : false
		});
	}
}

(() => {
	app = new Main();
	window.app = app; //new Main();

	window.googleMapsReady = () => {
		console.log("maps ready callbackl");
		Utils.DispatchEvent.dispatch(Events.external.maps.ready, {});
		console.log("dispatched", Events.external.maps.ready);
	}

	if(document.addEventListener) {
		document.addEventListener('DOMContentLoaded', app.start.bind(app), false);
	}

	if (window.addEventListener) {
		window.addEventListener('load', app.start.bind(app));
	}
	else {
		if (window.onload) {
			var current = window.onload;

			window.onload = function (evt) {
				current(evt);
				app.start();
			}
		}
		else {
			window.onload = onLoad;
		}
	}
})();