/**
 * project: Pimcore
 * Created by EBiermann on 01.10.2019.
 *
 * https://stackoverflow.com/questions/34655616/create-an-instance-of-a-class-in-es6-with-a-dynamic-name
 */


const components = {
	'Header': () => import( "../components/Header" ),
	'Breadcrumb': () => import( "../components/Breadcrumb" ),
	'Intro': () => import( "../components/Intro" ),
	'Map': () => import( "../components/Map" ),
	'Gallery': () => import( "../components/Gallery" ),
	'Timeline': () => import( "../components/Timeline" ),
	'Form': () => import( "../components/Form" ),
	'Hamburger': () => import( "../components/Hamburger" )

	//'ClickableNavigation': () => import( "../components/ClickableNavigation" ) // WORK IN PROGRESS

	//'Preloader': () => import( "../components/Preloader" ),
	//'Gallery': () => import( "../components/Gallery/Gallery" ),
	//'Form': () => import( "../components/form/Form" ),
	//'Slideshow': () => import( "../components/slideshow/Slideshow" ),
	//'Jumplinks': () => import( "../components/Jumplinks" ),
	//'Section': () => import("../components/Section"),
	//'Video': () => import( "../components/Video" ),
	//'Totop': () => import( "../components/Totop" ),
	//'Product':() => import( "../components/Product" ),
	//'Zoomimage':() => import( "../components/Zoomimage" ),
	//'Lightbox': () => import( "../components/Lightbox" )
}

export default async function ComponentFactory(name) {
	//console.log("ComponentFactory", name, components);
	//return components[name];

	if (components[name]) {
		return components[name]()
			.then(( {default: componentClass }) => {
				return componentClass
			})
			.catch((error) => 'An error occurred while loading the component' + error);
	}
}